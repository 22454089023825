* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Global Elements */

html {
    scroll-behavior: smooth;
}

html,
body {
    height: 100%;
    margin: 0;
}

body {
    background-color: #f0f2f1;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #231f20;
    line-height: 1.3;
    letter-spacing: 0.035em;
}

h1, h2, h3, h4, h5 {
    font-weight: normal;
    color: #000000;
    line-height: 1.1;
    letter-spacing: 0.015em;
    margin: 0 0 12px 0;
}

h1 {
    font-size: 40px;
    margin-left: 10px;
}

h2 {
    font-size: 28px;
    line-height: 42px;
    margin: 0 0 6px 0;
}

h3 {
    font-size: 24px;
    line-height: 42px;
    margin: 0 0 6px 0;
}

h4 {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.035em;
    margin: 10px 0 8px 0;
}

h5 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.035em;
}

p,
.p {
    margin: 0 0 1em 0;
}

ol,
ul {
    margin: 0 0 1em 1em;
}

li {
    line-height: 1.5;
}

a {
    text-decoration: none;
}

p a {
    color: #2d5033;
    text-decoration: none;
    border-bottom: 1px dotted #2d5033;
}

p a.button.link {
    font-weight: 600;
}

:focus {
    outline: none;
}

.break {
    display: block;
    width: 100%;
    height: 0;
    border: none;
    margin: 0;
}

.divider {
    display: block;
    background-color: #2d5033;
    width: 100%;
    height: 1px;
    border: none;
    margin: 1em 0 0.5em 0;
}

.right {
    text-align: right;
}

.print-only {
    display: none;
}

.hide-print {
}

.hidden,
.hidden-input,
.hidden-input .MuiInputLabel-formControl,
.hidden-input .MuiInputBase-root {
    display: none;
    width: 0 !important;
    max-width: 0 !important;
    height: 0;
    margin: 0 !important;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
}

.mt-0 {
    margin-top: 0;
}

.mt-1 {
    margin-top: 1em;
}

.get-list-select-input {
    display: inline-block;
    position: relative;
    width: 100%;
}

.get-list-select-input .arrow {
    position: absolute;
    top: 12px;
    right: 6px;
    z-index: 1;
    pointer-events: none;
}

.virtualized-select {
}

.virtualized-select > div {
    min-height: 48px;
}

.virtualized-select.half-width {
    min-width: 50%;
}

.Select-placeholder, .Select--single > .Select-control .Select-value {
    position: relative;
    padding-left: 0;
}

.Select-control, .Select-clear-zone, .Select-arrow-zone {
    display: inherit;
}

.select-custom-class .Select-multi-value-wrapper {
    display: flex;
    flex-direction: row-reverse;
}

.Select-clear-zone {
    position: absolute;
    top: 8px;
    right: 20px;
}

.Select-arrow-zone {
    position: absolute;
    top: 8px;
    right: 0;
}

.btns-container {
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

.btns-container.right {
    justify-content: flex-end;
}

.btns-container.margin-top {
    margin-top: 1em;
}

.btns-container.margin-bottom {
    margin-bottom: 1em;
}

.btns-container > button {
    margin: 0 1em 0 0;
}

.btns-container > button:nth-last-of-type(1) {
    margin: 0;
}

.RaLayout-content-6 {
    z-index: initial !important;
}

.sidebar-menu {
    margin: 0;
}

.sidebar-menu .logo-link {
    display: block;
    width: 180px;
    height: 62px;
    margin: 6px 0 2px 12px;
    overflow: hidden;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.sidebar-menu .logo-link.collapsed {
    height: 0;
    margin: 0 0 0 12px;
}

.sidebar-menu .logo {
    width: 180px;
    height: 62px;
}

.avatar-title {
    background-color: #508242 !important;
    margin-right: 10px;
    vertical-align: bottom;
}

.avatar-title-card {
    background-color: #508242 !important;
    margin-right: 10px;
}

.filter-field {
    -webkit-align-self: flex-start;
    align-self: start;
}

.filter-field .MuiInputBase-root,
.filter-field .MuiFormLabel-root {
    font-size: 14px;
    line-height: 1;
}

.filter-field .MuiFilledInput-input {

}

.filter-field .MuiFormHelperText-root {
    font-size: 0;
    line-height: 0;
}

.datagrid-body tr {
    background-color: transparent;
}

.datagrid-body tr:nth-of-type(odd) {
    background-color: #fafafa;
}

.data-table-instructions {
    font-weight: 500;
    margin: 8px 8px 10px 16px;
}

.list-table-container {
    height: auto;
}

.list-react-table {
    width: 100%;
    border-collapse: collapse;
}

.list-react-table tr {
}

.show-modal .list-react-table tr:hover {
    background-color: #eeeeee;
}

.list-react-table th,
.list-react-table td {
    padding: 5px 14px 5px 14px;
    border-bottom: 1px solid #e0e0e0;
}

.MuiTableCell-root.td-sku {
    position: relative;
    white-space: nowrap;
    padding: 5px 36px 5px 12px;
}

.MuiTableCell-root.td-name {
    position: relative;
    padding: 5px 36px 5px 16px;
}

.list-table tr:nth-of-type(odd) {
    background-color: #fafafa;
}

.list-table .sort-icon {
    display: inline-block;
    width: 18px;
    height: 14px;
    margin: 2px 0 0 5px;
    vertical-align: top;
}

.list-table .sort-icon .icon-sort-arrow.disabled {
    opacity: 0.4;
}

.list-table .column-filter {
    margin: 2px 0 0 0;
}

.list-table .column-filter input {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #231f20;
    line-height: 1;
}

.list-table .column-filter .table-filter-default {
    width: 100%;
    background-color: #f0f2f1;
    border: 1px solid rgba(0, 0, 0, 0.54);
    border-radius: 3px;
    padding: 5px 6px;
}

.list-table-container .pagination {
}

.list-table-container .pagination button {
}

.table-icon-button.MuiButton-root {
    min-width: 40px;
    padding: 2px 0 2px 0;
}

.table-icon-button.MuiButton-root svg {
    font-size: 26px;
}

.order-item-list-container,
.location-list-container,
.inventory-history-container,
.warehouse-location-history-container {
    position: relative;
    min-width: 650px;
    max-height: 278px;
    overflow: auto;
    margin: 0 0 2em 0;
}

.order-item-list-container {
    max-height: 2000px;
}

.receipt-item-list-container {
    margin: 0 0 2em 0;
}

.inventory-history-container,
.warehouse-location-history-container {
    max-height: 376px;
    margin: 0 0 1em 0;
}

.location-product-item-list-container {
    position: relative;
    max-height: 376px;
    overflow: auto;
    margin: 0 0 1em 0;
}

.show-layout .field-bg .MuiFormControl-root > div {
    background-color: #fafafa;
}

.has-breaks {
    white-space: break-spaces;
}

.message-qty {
    display: block;
    position: relative;
    text-align: right;
    margin: 10px 0 0 0;
    padding: 0 20px 4px 6px;
    opacity: 0;
    transition: opacity 1.2s ease;
}

.message-qty.active {
    opacity: 1;
}

.label {
    display: block;
    color: rgba(0, 0, 0, 0.54);
    line-height: 1;
    letter-spacing: 0.00938em;
    margin: 0 0 1em 0;
    padding: 0;
}

.input-block,
.input-block.MuiFormControl-root {
    display: block;
    margin: 0 0 1em 0;
    vertical-align: top;
}

.input-inline,
.input-inline.MuiFormControl-root {
    display: inline-flex;
    margin: 0 1em 0 0;
    vertical-align: top;
}

.input-hidden,
.input-hidden.MuiFormControl-root {
    display: none !important;
    width: 0 !important;
    max-width: 0 !important;
    height: 0;
    margin: 0 !important;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
}

.input-hidden + div {
    display: none;
}

.input-inline .MuiFormControl-root {
    width: 100%;
}

.input-80,
.input-80.MuiFormControl-root {
    width: 80px;
    min-width: 100px;
}

.input-90,
.input-90.MuiFormControl-root {
    width: 90px;
    min-width: 110px;
}

.input-100,
.input-100.MuiFormControl-root {
    width: 100px;
    min-width: 120px;
}

.input-120,
.input-120.MuiFormControl-root {
    width: 120px;
    min-width: 140px;
}

.input-150,
.input-150.MuiFormControl-root {
    width: 150px;
}

.input-180,
.input-180.MuiFormControl-root {
    width: 180px;
}

.input-220,
.input-220.MuiFormControl-root {
    width: 220px;
}

.input-280,
.input-280.MuiFormControl-root {
    width: 280px;
}

.input-320,
.input-320.MuiFormControl-root {
    width: 320px;
}

.input-340,
.input-340.MuiFormControl-root {
    width: 340px;
}

.input-360,
.input-360.MuiFormControl-root {
    width: 360px;
}

.input-400,
.input-400.MuiFormControl-root {
    width: 400px;
}

.input-third {
    width: 30%;
    max-width: 33.333%;
}

.input-half {
    min-width: 50%;
}

.input-full {
    flex: 0 1 100%;
    width: -webkit-calc(100% - 1em);
    width: -moz-calc(100% - 1em);
    width: calc(100% - 1em);
}

.input-inline.third {
    flex: 0 1 30%;
    width: -webkit-calc(33% - 0.8em);
    width: -moz-calc(33% - 0.8em);
    width: calc(33% - 0.8em);
    margin: 0 1em 0 0;
}

.input-inline.half {
    flex: 0 1 50%;
    width: -webkit-calc(50% - 1em);
    width: -moz-calc(50% - 1em);
    width: calc(50% - 1em);
    margin: 0 1em 0 0;
}

.input-multiline {
    min-height: 100px;
    background: red;
}

.input-select-list,
.input-select-list.MuiFormControl-root {
    width: 320px;
    min-width: 320px;
    margin-right: 14px;
}

.input-right > .MuiFormControl-root > div,
.input-right > .MuiFormControl-root > div input,
.input-right > .MuiInputBase-root > input {
    text-align: right;
}

.input-right > .MuiFormControl-root > div span,
.input-right > .MuiInputBase-input > div span {
    display: block;
}

.input-right .MuiInputLabel-shrink {
    text-align: right;
    -webkit-transform-origin: top right;
    -moz-transform-origin: top right;
    transform-origin: top right;
}

.input-right .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense {
    transform: translate(6px, 7px) scale(0.75);
}

.input-margin-left > .MuiFormControl-root {
    margin-left: calc(1em + 20px);
}

.ra-input.input-right .MuiInputLabel-shrink {
    left: auto;
    right: 24px;
}

.ra-field.input-border-left,
.ra-input.input-border-left {
    border-left: 1px solid #2d5033;
}

.ra-input.input-border-left {
    padding-left: 1em;
}

.ra-field.input-border-right,
.ra-input.input-border-right {
    border-right: 1px solid #2d5033;
}

.ra-input.input-border-right {
    padding-right: 1em;
}

.ra-input.input-col {
    display: inline-block;
    float: left;
}

.input-table-small,
.input-table-small.MuiFormControl-root {
    width: auto;
    margin: 0 6px 0 0;
}

.input-table-small.number,
.input-table-small.number.MuiFormControl-root {
    width: 70px;
    min-width: 70px;
}

.input-table-small input,
.input-table-small input.MuiInputBase-input,
.input-table-small input.MuiOutlinedInput-input-input {
    font-size: 14px;
    padding: 5px 6px;
}

.input-table-small.mr-0 {
    margin-right: 0;
}

.input-table-small.multiline {
    width: 100%;
    padding: 0;
}

.input-table-small.update {
    width: calc(100% - 120px);
    padding: 0;
}

.input-table-small.product-name {
    display: inline-block;
    width: calc(100% - 80px);
    height: 100%;
    margin: 3px 0 0 0;
    padding: 0;
}

.input-table-small .MuiOutlinedInput-multiline {
    width: 100%;
    padding: 5px 12px 5px 12px;
}

.table-align-right,
.MuiTableCell-root.table-align-right,
.MuiTableCell-root.right {
    text-align: right;
}

.MuiIconButton-root.Mui-disabled.MuiCheckbox-root {
    color: rgba(0, 0, 0, 0.16);
}

.list-page .MuiCard-root {
    margin-top: 0;
}

.list-page .MuiToolbar-regular {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
}

.list-page .MuiToolbar-regular.MuiToolbar-gutters {
    -webkit-align-items: center;
    align-items: center;
}

.list-page .MuiToolbar-regular > .MuiToolbar-regular.MuiToolbar-gutters form {
    display: flex;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.list-page .MuiToolbar-regular > .MuiToolbar-regular.MuiToolbar-gutters .MuiButton-root {
    white-space: nowrap;
}

.list-page .MuiToolbar-regular > .MuiToolbar-regular,
.create-page > .MuiToolbar-regular,
.edit-page > .MuiToolbar-regular,
.show-page > .MuiToolbar-regular {
    padding-top: 30px;
    margin-left: 12px;
    overflow: auto;
}

.list-page .MuiToolbar-regular > .MuiToolbar-regular {
    overflow: visible;
}

.list-page .MuiPaper-root > .MuiToolbar-regular {
    background-color: rgb(215, 224, 230);
    margin-bottom: 48px;
}

.input-bulk-edit {
    width: 240px;
    margin: 20px 12px 0 0;
}

.btn-bulk-edit,
.MuiButton-root.btn-bulk-edit {
    margin: 28px 0 0 0;
}

.list-page .MuiToolbar-root > form {
    margin-top: 0;
    margin-bottom: 8px;
}

.list-card-container {
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
}

.list-card {
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: stretch;
    align-items: stretch;
    min-height: 0;
}

.list-card,
.field-column {
    flex: 1 1 auto;
    margin: 0 0 1em 0;
    border-left: solid 6px #508242;
    vertical-align: top;
}

.list-card.half,
.field-column.half {
    width: -webkit-calc(50% - 1em);
    width: -moz-calc(50% - 1em);
    width: calc(50% - 1em);
    margin: 0 1em 1em 0;
}

.field-column.half {
    flex: 0 1 50%;
    display: inline-flex;
}

.list-card.last,
.field-column.last {
    margin: 0 0 1em 0;
}

.list-card .MuiCardActions-root {
    display: flex;
    align-items: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.list-card .MuiCardActions-root a,
.list-card .MuiCardActions-root button {
    white-space: nowrap;
    margin: 0 4px 1em 4px;
}

.tab-panel {
    padding: 1em 16px 20px 16px;
}

.tab-panel.hidden {
    display: none;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
}

.file-browse-container {
    display: block;
    margin: 0 0 1em 0;
}

.product-image-upload {
    display: block;
}

.product-image-upload > .MuiFormControl-root > div > div {
    background-color: #f0f0f0;
    max-width: 700px;
    padding: 10px 8px;
    -webkit-border-top-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    border-top-left-radius: 4px;
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topright: 4px;
    border-top-right-radius: 4px;
}

.product-image-upload > .MuiFormControl-root .previews {
    background-color: transparent;
    padding: 0;
}

.product-image-upload .previews,
.product-image.thumbnail,
.product-image.list-thumbnail {
    display: inline-block;
    max-width: 100%;
    margin: 0;
    position: relative;
}

.product-image.list-thumbnail {
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    width: 60px;
    height: 60px;
    border: 1px solid #cccccc;
    cursor: pointer;
}

.product-image.thumbnail .delete-icon {
    position: absolute;
    top: 2px;
    right: 2px;
}

.product-image.thumbnail .delete-icon svg.MuiSvgIcon-root {
    fill: #b4212d;
}

.product-image-upload .previews img,
.product-image.thumbnail img,
.product-image.list-thumbnail img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 500px;
    margin: 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.product-image.list-thumbnail img {
    display: flex;
    -webkit-align-self: center;
    align-self: center;
    max-height: 100%;
    border: none;
}

.product-image.list-thumbnail .enlarge {
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #ffffff;
    width: 0;
    height: 0;
    transform: translate(-50%, -50%);
    z-index: 10;
    opacity: 0;
    border: 1px solid #cccccc;
    pointer-events: none;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    transition: all 0.1s linear;
}

.product-image.list-thumbnail .enlarge img {
    display: flex;
    -webkit-align-self: center;
    align-self: center;
    max-height: 100%;
    border: none;
}

.product-image.list-thumbnail:hover .enlarge {
    width: 200px;
    height: 200px;
    opacity: 1;
}

@media all and (max-width: 1024px) {
    .input-inline.third {
        flex: 0 1 50%;
        width: -webkit-calc(50% - 1em);
        width: -moz-calc(50% - 1em);
        width: calc(50% - 1em);
    }
}

@media all and (max-width: 768px) {
    .input-inline.third,
    .input-inline.half {
        flex: none;
        width: 100%;
    }
}

@media print {
    html, body {
        background: #ffffff;
        overflow: hidden;
    }

    .print-only {
        display: block;
    }

    .hide-print {
        display: none !important;
    }

    .page-break {
        display: block;
        page-break-after: always;
    }

    .content {
        -webkit-print-color-adjust: exact;
    }

    h1 {
        font-size: 22pt;
        margin: 0 0 2pt 0;
    }

    h2 {
        font-size: 18pt;
        margin: 4pt 0 4pt 0;
    }

    .input-block {
        width: 100%;
        max-width: 100%;
    }

    .input-inline {
        margin-right: 2%;
    }

    .input.MuiFormControl-root,
    .input-block.MuiFormControl-root,
    .input-inline.MuiFormControl-root {
        margin-bottom: 4pt;
    }

    .input .MuiFilledInput-input {
        padding: 24px 12px 6px;
    }

    .input-quarter {
        width: 23%;
        max-width: 23%;
    }

    .input-third {
        width: 31%;
        max-width: 31%;
    }

    .input-half {
        width: 47%;
        min-width: 47%;
        max-width: 47%;
    }

    .input-two-thirds {
        width: 64%;
        max-width: 64%;
    }

    .input-border-left {
        padding-left: 20pt;
        border-left: 2pt solid #2d5033;
    }

    .input .MuiFilledInput-root {
        background-color: rgba(0, 0, 0, 0);
    }

    .input .MuiFilledInput-root:before,
    .input-bg .MuiFilledInput-root:after {
        border-bottom-color: transparent;
    }

    .input.has-bg .MuiFilledInput-root {
        background-color: rgba(0, 0, 0, 0.04);
    }

    .input.has-bg .MuiFilledInput-root:before,
    .input.has-bg .MuiFilledInput-root:after {
        border-bottom-color: transparent;
    }

    .field-label {
        display: block;
        font-size: 0.875em;
        line-height: 1;
        color: rgba(0, 0, 0, 0.54);
        margin: 0 0 2pt 0;
    }

    .field-textfield {
        display: block;
        width: 100%;
        margin: 0 0 6pt 0;
    }

    .table-print {
        border-collapse: collapse;
        margin: 0 0 8pt 0;
    }

    .table-print tr {
        line-height: 1;
        margin: 0;
    }

    .table-print td {
        vertical-align: middle;
        white-space: normal;
        word-break: normal;
        margin: 0;
        padding: 5pt 5pt 5pt 5pt;
    }

    .table-print .label {
        white-space: nowrap;
        width: 20%;
    }

    .table-print .value {
        width: 30%;
    }

    .table-print .table-half {
        width: 48%;
    }

    .table-print .align-right {
        text-align: right;
    }

    .table-print .border-left {
        margin-left: 12pt;
        padding-left: 20pt;
        border-left: 1pt solid #2d5033;
    }

    .has-breaks {
        white-space: break-spaces;
    }

    .order-item-list-container {
        width: 100%;
        min-width: 0;
        max-width: 100%;
        max-height: none;
        transform: scale(0.98);
    }

    .MuiTableCell-root {
        white-space: normal;
        word-break: break-word;
        padding: 5px 8px 5px 8px;
    }

    .product-image.list-thumbnail {
        width: 50px;
        height: 50px;
    }

    .MuiTableCell-root.td-title {
        white-space: normal;
        word-break: break-word;
    }

    .MuiTableCell-root.td-name {
        white-space: normal;
        word-break: normal;
        padding: 5pt 5pt 5pt 5pt;
    }

    .MuiTableCell-root.td-sku {
        white-space: normal;
        word-break: normal;
        padding: 5pt 5pt 5pt 5pt;
    }
}
