.receipt-label {
    display: none;
    width: 8in;
    height: 4in;
    font-family: 'Roboto', 'Helvetica', sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    color: #272727;
    line-height: 1.1;
    letter-spacing: -0.015em;
    margin: 0;
}

.receipt-label .content {
    width: 6in;
    height: 4in;
    margin: 0;
    padding: 0 2pt 0 18pt;
    page-break-after: always;
}

.receipt-label .receipt-print-item {
    margin: 12pt 0 0 0;
    border-radius: 8pt;
    page-break-after: always;
}

.receipt-label h2 {
    height: 55pt;
    font-size: 2.5rem;
    font-weight: 600;
    color: #272727;
    letter-spacing: -0.025em;
    line-height: 1;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}

.receipt-label td {
    font-family: 'Roboto', 'Helvetica', sans-serif;
    font-size: 1.25rem;
    font-weight: 500;
    color: #272727;
    line-height: 1;
    margin: 0;
    padding: 8pt 10pt 1pt 0;
    vertical-align: top;
    border-bottom: 1pt solid #666666;
}

.receipt-label td.label {
    font-size: 1.15rem;
    width: 11%;
    border-bottom: 1pt solid transparent;
}

.receipt-label td.line {
    width: 89%;
}

.receipt-label td.line.half {
    width: 80%;
}

.receipt-label td.label.sku {
    font-size: 1.35rem;
    padding-top: 12pt;
}

.receipt-label td.line.sku {
    font-size: 3.5rem;
    font-weight: 600;
    letter-spacing: -0.025em;
    line-height: 1;
    white-space: nowrap;
    padding-top: 8pt;
    border-bottom: 1pt solid transparent;
}

.receipt-label td.right {
    text-align: right;
}

.receipt-label td.nowrap {
    white-space: nowrap;
}

.receipt-label td.center {
    text-align: center;
    padding: 10pt 10pt 1pt 10pt;
}

.receipt-label .receipt-id,
.receipt-label .tracking-number {
    display: inline-block;
    font-family: 'Roboto', 'Helvetica', sans-serif;
    font-size: 1.25rem;
    font-weight: 500;
    color: #272727;
    line-height: 1;
    text-transform: uppercase;
    margin: 6pt 10pt 0 0;
}

@media print {
    .receipt-label {
        display: block;
    }
}
